import '../../common/js/common';
import './frame-pp.scss';
import Vue from 'vue';
import header from '../header/header-pp/header-pp';
import footer from '../footer/footer-commons/lib/footer-base';

/* eslint-disable no-new*/
new Vue(header);
new Vue(footer);
/* eslint-enable*/
